import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {MRIHome} from "./containers/mri/MRIHome";
import BoneLayoutDefault from "./components/layout/Layout";
import {MRICodeHome} from "./containers/mri/MRICodeHome";

const routes = [
    {
        path: "/",
        component: MRIHome,
        service: "mri",
        className: "mri-layout",
        title: "Private MRI Scan No Referral Needed - Revolutionary Musculoskeletal Assessment",
        "description": "Step into the future of medical imaging with our Open MRI at MSK House. This advanced technology offers unparalleled clarity in diagnosing musculoskeletal conditions, all in a comfortable, open environment. Say goodbye to the discomfort of traditional MRI and experience Private MRI Scan that adapts to your needs.",
        layout: true,
    },
    {
        path: `/${process.env.REACT_APP_COUPON_CODES}`,
        component: MRICodeHome,
        service: "mri",
        className: "mri-layout",
        title: "Private MRI Scan No Referral Needed - Revolutionary Musculoskeletal Assessment",
        "description": "Step into the future of medical imaging with our Open MRI at MSK House. This advanced technology offers unparalleled clarity in diagnosing musculoskeletal conditions, all in a comfortable, open environment. Say goodbye to the discomfort of traditional MRI and experience Private MRI Scan that adapts to your needs.",
        layout: true,
    },
];
const RedirectHandler = (): any => {
    return <Navigate to="/" replace />;
};

export const App = (): React.ReactElement => {
    const location = useLocation();

    useEffect(() => {
        (window as any).matchedCode = location.pathname?.toLowerCase()?.includes(process.env.REACT_APP_COUPON_CODES?.toLowerCase() as string) ?
                process.env.REACT_APP_COUPON_CODES : undefined;
    }, [location.pathname]);

    return (
        <Routes>
            {routes.map(({ path, component: Component, title, description, className, layout, service }, index) => (
                <Route
                    key={index}
                    path={path}
                    element={
                        <>
                            {title ? (
                                <Helmet>
                                    <title>{title}</title>
                                    <link rel="canonical" href={`https://regenphd.com${path}`} />
                                    <meta name="description" content={description} />
                                    <meta property="og:title" content={title} />
                                    <meta property="og:description" content={description} />
                                    <meta name="twitter:title" content={title} />
                                    <meta name="twitter:description" content={description} />
                                </Helmet>
                            ) : null}
                            {layout ? (
                                <BoneLayoutDefault service={service} className={className}>
                                    <Component />
                                </BoneLayoutDefault>
                            ) : (
                                <Component />
                            )}
                        </>
                    }
                />
            ))}

            <Route path="*" element={<RedirectHandler />} />
        </Routes>
    );
};
