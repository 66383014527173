import React from "react";
import classNames from "classnames";
import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import Image from "../elements/Image";

class Footer extends React.Component {
    render() {
        const {className, topOuterDivider, topDivider, ...props} = this.props;

        const classes = classNames(
            "site-footer invert-color center-content-mobile",
            topOuterDivider && "has-top-divider",
            className,
        );

        return (
            <footer {...props} className={classes}>
                <div className="container">
                    <div className={classNames("site-footer-inner", topDivider && "has-top-divider")}>
                        <div className="footer-top space-between text-xxs" style={{ alignItems:"center" }}>
                            <Logo/>
                            <a href="https://www.cqc.org.uk/provider/1-8692595311" rel="noreferrer" target="_blank">
                                <Image
                                    src={require("../../assets/Images/mri/cqc.png")}
                                    alt="CQC"

                                    width={160}
                                    height={80}

                                />
                            </a>
                            <a style={{ marginLeft: 20 }} href="https://www.nhs.uk/services/independent-sector-provider/msk-doctors/I7G6T"
                               rel="noreferrer" target="_blank">
                                <Image
                                    src={require("../../assets/Images/mri/nhs-provider.png")}
                                    alt="NHS Provider"
                                    width={160}
                                    height={60}
                                />
                            </a>
                            <FooterSocial/>
                        </div>
                        <div className="footer-bottom space-between text-xxs">
                            <FooterNav/>
                            <div className="footer-copyright text-white">&copy; 2025 Open MRI, all rights reserved. <div
                                className="footer-copyright text-center opacity-50 mt-2 text-white" style={{ marginBottom: 40 }}>Association with
                                MSK Doctors.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
