import React from "react";
import classNames from "classnames";
import {Grid} from "@mui/material";
import Allianz from "../../../assets/Images/mri/allianz_logo.png"
import Axa from "../../../assets/Images/mri/axa.png"
import Bupa from "../../../assets/Images/mri/bupa_logo.png"
import Cigna from "../../../assets/Images/mri/cigna.png"
import GeneralMedical from "../../../assets/Images/mri/general-medical.png"
import Healix from "../../../assets/Images/mri/healix.png"
import LoveEveryDrop from "../../../assets/Images/mri/love-every-drop-2.png"
import Vitality from "../../../assets/Images/mri/vitality.png"
import Wpa from "../../../assets/Images/mri/wpa.png"
import SectionHeader from "./partials/SectionHeader";

class News extends React.Component {
	render() {
		return (
			<section className={classNames("news section")} style={{ paddingTop: 0 }}>
				<div className="container">
					<div className={classNames("news-inner section-inner")}>
						<SectionHeader
							className="center-content"
							data={{
								title: "Our Insurance Partners",
								paragraph: "",
							}}
						/>
						<Grid container spacing={2}>
							<Grid item xs={4} md={3} className="text-center">
								<img src={Bupa} alt="Bupa" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center">
								<img src={Allianz} alt="Allianz" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center">
								<img src={LoveEveryDrop} alt="LoveEveryDrop" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center" style={{paddingTop: 30}}>
								<img src={GeneralMedical} alt="GeneralMedical" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center" style={{paddingTop: 30}}>
								<img src={Cigna} alt="Cigna" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center" style={{paddingTop: 30}}>
								<img src={Healix} alt="Healix" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center" style={{paddingTop: 30}}>
								<img src={Axa} alt="Axa" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center" style={{paddingTop: 30}}>
								<img src={Wpa} alt="Wpa" className="news-image"/>
							</Grid>
							<Grid item xs={4} md={3} className="text-center" style={{paddingTop: 30}}>
								<img src={Vitality} alt="Vitality" className="news-image"/>
							</Grid>
						</Grid>
					</div>
				</div>
			</section>
		);
	}
}

export default News;
