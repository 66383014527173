import React, {useRef} from "react";
import classNames from "classnames";
import HeroSplit from "components/mri/sections/HeroSplit";
import FeaturesTiles from "components/mri/sections/FeaturesTiles";
import BoneDensity from "components/mri/sections/BoneDensity";
import {ArrowRightOutlined, EnvironmentOutlined, ExportOutlined} from "@ant-design/icons";
import News from "components/mri/sections/News";
import {motion, useInView} from "framer-motion";
import Step1Img from "../../assets/Images/mri/step-1.svg"
import Step2Img from "../../assets/Images/mri/step-2.svg"
import Step3Img from "../../assets/Images/mri/step-3.svg"
import Step4Img from "../../assets/Images/mri/step-4.svg"
import Step5Img from "../../assets/Images/mri/step-5.svg"
import SecondHeroSection from "components/mri/sections/SecondHeroSection";
import FAQs from "components/mri/sections/FAQs";
import Pricing from "components/mri/sections/Pricing";
import Testimonials from "components/mri/sections/Testimonials";
import {FixedFloatingButtons} from "../../components";
import SectionHeader from "../../components/mri/sections/partials/SectionHeader";
import {Button, Grid, Stack} from "@mui/material";

const openingHours = [
    {day: "Monday", time: "08:00 AM - 05:30 PM"},
    {day: "Tuesday", time: "08:00 AM - 05:30 PM"},
    {day: "Wednesday", time: "08:00 AM - 05:30 PM"},
    {day: "Thursday", time: "08:00 AM - 05:30 PM"},
    {day: "Friday", time: "08:00 AM - 05:30 PM"},
    {day: "Saturday", time: "Closed"},
    {day: "Sunday", time: "Closed"},
];
export const MRIHome = () => {
    // Define animations for sliding from left and right
    const leftToRight = {
        hidden: {opacity: 0, x: -100, y: 0},
        visible: {opacity: 1, x: 0, y: 0},
    };

    const rightToLeft = {
        hidden: {opacity: 0, x: 100, y: 0},
        visible: {opacity: 1, x: 0, y: 0},
    };

    const transition = {duration: 0.8, ease: "easeInOut"};

    // Create refs for each section
    const heroSplitRef = useRef(null);
    const featuresTilesRef = useRef(null);
    const testimonialsRef = useRef(null);
    const faqsRef = useRef(null);

    // Use useInView hook to trigger animations when each section is in view
    const isHeroSplitInView = useInView(heroSplitRef, {once: false});
    const isFeaturesTilesInView = useInView(featuresTilesRef, {once: false});
    const isTestimonialsInView = useInView(testimonialsRef, {once: false});
    const isFaqsInView = useInView(faqsRef, {once: false});

    return (
        <div className="bone">
            <motion.div
                ref={heroSplitRef}
                initial="hidden"
                animate={isHeroSplitInView ? "visible" : "hidden"}
                variants={leftToRight}
                transition={transition}
            >
                <HeroSplit hasBgColor invertColor/>
            </motion.div>
            <div>
                <section className={classNames("news section")} style={{paddingTop: 0}}>
                    <div className="container">
                        <div className={classNames("news-inner section-inner")}>
                            <SectionHeader
                                className="center-content"
                                data={{
                                    title: "Location",
                                    paragraph: "",
                                }}
                            />
                            <iframe
                                title="London Rd, Silk Willoughby, Sleaford NG34 8NY, United Kingdom"
                                src="https://www.google.com/maps?q=Open+MRI+scan+%7C+Sleaford,+United+Kingdom&t=&z=17&ie=UTF8&iwloc=&output=embed"
                                width="100%"
                                height="450"
                                style={{border: 0}}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <Grid style={{marginTop: 20}} container spacing={2} justifyContent="space-between">
                                <Grid item xs={12} md={6}>
                                    <EnvironmentOutlined style={{fontSize: 20}}/><span className="ml-4 font-mlg">London Rd, Silk Willoughby, Sleaford NG34 8NY, United Kingdom</span>
                                </Grid>
                                <Grid item xs={12} md={6} className="text-end">
                                    <Button
                                        variant="text"
                                        className="text-capitalize color-blue font-semibold font-md"
                                        onClick={() => window.open("https://www.google.com/maps/place/Open+MRI+scan+%7C+Sleaford/@52.9771969,-0.4270199,17.78z/data=!4m6!3m5!1s0x48783ff56179bb1d:0xb8125b197cae2d6e!8m2!3d52.9777554!4d-0.4260127!16s%2Fg%2F11sfs4k36h?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D", "_blank")}
                                    >
                                        <ExportOutlined style={{fontSize: 20}}/>
                                        <span className="ml-4">Open in Google Maps</span>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{marginTop: 20}} justifyContent="space-between">

                                <Grid item xs={12} md={6}>
                                    <h5 className="font-semibold">Directions</h5>
                                    <p>
                                        We are directly opposite to the Pennells - Four Season Garden centre on London
                                        Road (A153). There are plenty of free parking with very easy wheel chair access.
                                    </p>
                                    <img src={require("../../assets/Images/mri/parking.png")} alt="Parking"
                                         style={{width: "50%", margin: "auto"}}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <h5 className="font-semibold">Opening Hours</h5>
                                    <Grid container spacing={2} justifyContent="space-between">
                                        {openingHours.map(o => <>
                                                <Grid item xs={6}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <div className="blue-dot"/>
                                                        <p className="mb-0">{o.day}</p></Stack>
                                                </Grid>
                                                <Grid item xs={6} className="text-center">
                                                    <p className="mb-0">{o.time}</p>
                                                </Grid>
                                            </>
                                        )}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <section className={classNames("news section")} style={{paddingTop: 0}}>
                    <div className="container">
                        <div className={classNames("news-inner section-inner")} style={{ paddingBottom: 0 }}>
                            <Grid container spacing={2} className="steps-container">
                                <Grid item xs={6} md={2.4} className="text-center">
                                    <div className="position-relative">
                                        <h2 className="font-semibold">Step 1</h2>
                                        <img src={Step1Img} alt="Steps" />
                                        <p className="font-medium font-mlg mt-2">Decide what to scan</p>
                                        <div className="arrow">
                                        <ArrowRightOutlined />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2.4} className="text-center">
                                    <div className="position-relative">
                                        <h2 className="font-semibold">Step 2</h2>
                                        <img src={Step2Img} alt="Steps" />
                                        <p className="font-medium font-mlg mt-2">Pick a time and date</p>
                                        <div className="arrow">
                                            <ArrowRightOutlined />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2.4} className="text-center">
                                    <div className="position-relative">
                                        <h2 className="font-semibold">Step 3</h2>
                                        <img src={Step3Img} alt="Steps" />
                                        <p className="font-medium font-mlg mt-2">Fill in online form</p>
                                        <div className="arrow">
                                            <ArrowRightOutlined />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2.4} className="text-center">
                                    <div className="position-relative">
                                        <h2 className="font-semibold">Step 4</h2>
                                        <img src={Step4Img} alt="Steps" />
                                        <p className="font-medium font-mlg mt-2">Have Open MRI Scan</p>
                                        <div className="arrow">
                                            <ArrowRightOutlined />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2.4} className="text-center">
                                    <div className="position-relative">
                                        <h2 className="font-semibold">Step 5</h2>
                                        <img src={Step5Img} alt="Steps" />
                                        <p className="font-medium font-mlg mt-2">Receive digital report</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <Pricing/>
            </div>
            <div style={{background: "#F3F5F8"}}>
                <News className="illustration-section-01"/>
            </div>

            <motion.div
                ref={featuresTilesRef}
                initial="hidden"
                animate={isFeaturesTilesInView ? "visible" : "hidden"}
                variants={leftToRight}
                transition={transition}
            >
                <FeaturesTiles/>
            </motion.div>
            <SecondHeroSection/>
            <BoneDensity topDivider bottomOuterDivider/>

            <motion.div
                ref={faqsRef}
                initial="hidden"
                animate={isFaqsInView ? "visible" : "hidden"}
                variants={rightToLeft}
                transition={transition}
            >
                <FAQs/>
            </motion.div>
            <motion.div
                ref={testimonialsRef}
                initial="hidden"
                animate={isTestimonialsInView ? "visible" : "hidden"}
                variants={leftToRight}
                transition={transition}
            >
                <Testimonials/>
            </motion.div>
            <FixedFloatingButtons/>
        </div>
    );
};
